<template>
  <div>
    <div v-if="!loadingPage" style="display:flex;justify-content:center;min-height:100vh;width:100vw;"  class="landing-wrapper" :class="{ fullscreen: landing && landing.sections.styling.Fullscreen }">
      <div v-if="landing" class="device-container" :style="{ backgroundImage: `url(${landing.sections.styling.BackgroundImage})` }">
        <div class="device-container-inner" :style="deviceContainerInner">
          <div v-if="landing.sections.styling.BackgroundVideo" class="videoContainer">
            <iframe v-if="videoType === 'youtube'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe v-else-if="videoType === 'vimeo'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            <video v-else :src="videoSource" autoplay muted loop></video>
          </div>
          <div class="landing-widget" :style="landingWidgetStyle">
            <div ref="graphicContainer" class="landing-container" :style="landingContainerStyle">
              <div class="contentContainer" :style="contentContainerStyle">
                <div class="section-container" :style="containerSectionStyle">
                  <div v-if="landing.sections.logo.Show" class="logo-container" :style="logoContainerStyle">
                    <a :href="landing.sections.logo.Link">
                      <img
                        :src="landing.sections.logo.Image"
                        alt="Logo"
                        :style="{
                          maxWidth: landing.sections.logo.MaxWidth + 'px',
                          width: '100%',
                          margin: '0 auto',
                        }"
                      />
                    </a>
                  </div>
                  <div class="main-container" :style="mainContainerStyle">
                    <template v-if="activeStep === 'thanks'">
                      <div :style="{
                        fontSize: `${landing.sections.thanks.FontSize}px`,
                        textAlign: 'center',
                        color: landing.sections.thanks.Color,
                        padding: `${landing.sections.thanks.Padding}px`,
                      }">
                        {{ landing.sections.thanks.Content }}
                      </div>

                        <div v-if="!landing.sections.thanks.isShareSocial" style="text-align:center;">
                          <a href="javascript:;" :style="actionButtonStyle" @click="selectStep('inputs')">
                            {{ landing.sections.thanks.BackLabel }}
                          </a>
                        </div>
                        <div v-else style="text-align:center;" class="social-box">
                          <a :href="`https://www.facebook.com/sharer/sharer.php?u=${href}`">
                            <i class="uil uil-facebook"></i>
                          </a>
                          <a :href="`https://twitter.com/intent/tweet?&text=${href}`">
                            <i class="uil uil-twitter"></i>
                          </a>
                        </div>
                    </template>
                    <template v-else-if="activeStep === 'vote'">
                      <div
                        class="widget-block widget-content"
                      >
                        <div style="text-align:center;">
                          <div :style="{
                            color: landing.sections.main.HeadingColor,
                            fontSize: `${landing.sections.main.FontSize}px`,
                            textAlign: `${landing.sections.main.Align}`,
                          }">
                            {{ landing.sections.main.Question }}
                          </div>
                        </div>
                      </div>
                      <div class="inputsSection">
                        <span :style="{
                          display: 'block', 
                          textAlign: `${landing.sections.main.Align}`,
                          color: landing.sections.main.DescriptionColor,
                          padding: `${landing.sections.main.DescriptionPadding}px`,
                          fontSize: `${landing.sections.main.DescriptionFontSize}px`,
                        }">
                          {{ landing.sections.main.QuestionDescription }}
                        </span>
                        <div class="options-section section-select mt-2">
                          <div v-for="(item, key) in voteOptions" :key="key" class="form-group">
                            <div class="custom-control custom-checkbox" :class="{ 'allow-multiple': landing.votes.options.AllowMultiple }" :style="optionStyle">
                              <input :id="item.id" v-model="voteCheckedOptions[item.id]" type="checkbox" class="custom-control-input"  @click="onVoteOptionChange" />
                              <label class="custom-control-label" :for="item.id">
                                {{ item.text }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="voteError" class="text-danger text-center mb-2">{{ voteError }}</div>
                      <div
                        class="widget-block widget-content"
                      >
                        <div style="text-align:center;">
                          <button :style="voteActionButtonStyle" @click="onVoteSubmit">
                            {{ landing.votes.actionButton.Label }}
                          </button>
                          <div class="disclaimer-section">
                            <b-form-checkbox v-if="landing.votes.actionButton.ShowDisclaimerCheckBox" required name="disclaimer">
                              <span :style="actionDisclaimerStyle">{{ landing.votes.actionButton.Disclaimer | legal(businessName) }}</span>
                            </b-form-checkbox>
                            <span v-else :style="actionDisclaimerStyle">{{ landing.votes.actionButton.Disclaimer | legal(businessName) }}</span>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="activeStep === 'inputs'">
                      <div
                        class="widget-block widget-content"
                      >
                        <div style="text-align:center;">
                          <div :style="{
                            color: landing.sections.main.HeadingColor,
                            fontSize: `${landing.sections.main.FontSize}px`,
                            textAlign: `${landing.sections.main.Align}`,
                          }">
                            {{ landing.sections.main.Content }}
                          </div>
                        </div>
                      </div>
                      <validation-observer v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(sendContact)">
                          <div class="inputsSection">
                            <span :style="{
                              display: 'block', 
                              textAlign: `${landing.sections.main.Align}`,
                              color: landing.sections.main.DescriptionColor,
                              padding: `${landing.sections.main.DescriptionPadding}px`,
                              fontSize: `${landing.sections.main.DescriptionFontSize}px`,
                            }">
                              {{ landing.sections.main.Description }}
                            </span>
                            <div v-if="landing.sections.inputs.ShowName" class="input-group" :style="inputGroupStyle">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                :rules="landing.sections.inputs.NameRequired ? 'required' : ''">
                                <input v-model="contact.name" type="text" 
                                  :placeholder="landing.sections.inputs.NamePlaceholder" :style="inputStyle"
                                  :class="{ 'is-invalid': errors.length }">
                                <b-form-invalid-feedback v-if="errors.length">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </div>
                            <div v-if="landing.sections.inputs.ShowBirthday" class="row">
                              <div class="col-12" :style="{ color: landing.sections.inputs.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                                {{ landing.sections.inputs.BirthdayLabel }}
                              </div>
                              <div class="col-6">
                                <div  class="input-group" :style="inputGroupStyle">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="BMonth"
                                    :rules="landing.sections.inputs.BirthdayRequired ? 'required' : ''">
                                      <select v-model="contact.bday_month" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                        <option value="">Month</option>
                                        <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                      </select>
                                      <b-form-invalid-feedback v-if="errors.length">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                  </validation-provider>
                                </div>
                              </div>
                              <div class="col-6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="BDay"
                                  :rules="landing.sections.inputs.BirthdayRequired ? 'required' : ''">
                                    <div class="input-group" :style="inputGroupStyle">
                                      <select v-model="contact.bday_day" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                        <option value="">Day</option>
                                        <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                      </select>
                                      <b-form-invalid-feedback v-if="errors.length">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </div>
                                </validation-provider>
                              </div>
                            </div>

                            <div v-if="landing.sections.inputs.ShowAnniversary" class="row">
                              <div class="col-12" :style="{ color: landing.sections.inputs.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                                {{ landing.sections.inputs.AnniversaryLabel }}
                              </div>
                              <div class="col-6">
                                <div  class="input-group" :style="inputGroupStyle">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="AMonth"
                                    :rules="landing.sections.inputs.AnniversaryRequired ? 'required' : ''">
                                      <select v-model="contact.anniv_month" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                        <option value="">Month</option>
                                        <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                      </select>
                                      <b-form-invalid-feedback v-if="errors.length">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                  </validation-provider>
                                </div>
                              </div>
                              <div class="col-6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="ADay"
                                  :rules="landing.sections.inputs.AnniversaryRequired ? 'required' : ''">
                                    <div class="input-group" :style="inputGroupStyle">
                                      <select v-model="contact.anniv_day" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                        <option value="">Day</option>
                                        <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                      </select>
                                      <b-form-invalid-feedback v-if="errors.length">
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </div>
                                </validation-provider>
                              </div>
                            </div>

                            <div v-if="landing.sections.inputs.ShowBusinessName" class="input-group" :style="inputGroupStyle">
                              <validation-provider
                                v-slot="{ errors }"
                                name="BusinessName"
                                :rules="landing.sections.inputs.BusinessNameRequired ? 'required' : ''"
                              >
                                <input v-model="contact.business_name" type="text" 
                                  :placeholder="landing.sections.inputs.BusinessNamePlaceholder" :style="inputStyle"
                                  :class="{ 'is-invalid': errors.length }">
                                <b-form-invalid-feedback v-if="errors.length">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </div>

                            <div v-if="landing.sections.inputs.ShowWebsiteUrl" class="input-group" :style="inputGroupStyle">
                              <validation-provider
                                v-slot="{ errors }"
                                name="WebsiteUrl"
                                :rules="landing.sections.inputs.WebsiteUrlRequired ? 'required' : ''"
                              >
                                <input v-model="contact.website_url" type="text" 
                                  :placeholder="landing.sections.inputs.WebsiteUrlPlaceholder" :style="inputStyle"
                                  :class="{ 'is-invalid': errors.length }">
                                <b-form-invalid-feedback v-if="errors.length">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </div>

                            <div v-for="(item, key) in sectionCustomFields" :key="key">
                              <template v-if="item.ShowField">
                                <div v-if="item.Type === 2" class="row">
                                  <div class="col-12" :style="{ color: landing.sections.inputs.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                                    {{ item.Placeholder }}
                                  </div>
                                  <div class="col-6">
                                    <div  class="input-group" :style="inputGroupStyle">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        :name="`${item.Name} Month`"
                                        :rules="item.Required ? 'required' : ''">
                                          <select v-model="contactCustomFields[item.Id + '_month']" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                            <option :value="undefined">Month</option>
                                            <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                          </select>
                                          <b-form-invalid-feedback v-if="errors.length">
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                      </validation-provider>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <validation-provider
                                      v-slot="{ errors }"
                                      :name="`${item.Name} Day`"
                                      :rules="item.Required ? 'required' : ''">
                                        <div class="input-group" :style="inputGroupStyle">
                                          <select v-model="contactCustomFields[item.Id + '_day']" :style="inputStyle" :class="{ 'is-invalid': errors.length }">
                                            <option :value="undefined">Day</option>
                                            <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                          </select>
                                          <b-form-invalid-feedback v-if="errors.length">
                                            {{ errors[0] }}
                                          </b-form-invalid-feedback>
                                        </div>
                                    </validation-provider>
                                  </div>
                                </div>
                                <div v-else class="input-group" :style="inputGroupStyle">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    :name="item.Name"
                                    :rules="item.Required ? 'required' : ''"
                                  >
                                    <input v-model="contactCustomFields[item.Id]" type="text" 
                                      :placeholder="item.Placeholder" :style="inputStyle"
                                      :class="{ 'is-invalid': errors.length }">
                                    <b-form-invalid-feedback v-if="errors.length">
                                      {{ errors[0] }}
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </div>
                              </template>
                            </div>
                            
                            <div v-if="landing.sections.inputs.ShowEmail" class="input-group" :style="inputGroupStyle">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                :rules="landing.sections.inputs.EmailRequired ? 'required|email' : 'email'">
                                <input v-model="contact.email" type="text" 
                                  :placeholder="landing.sections.inputs.EmailPlaceholder" :style="inputStyle"
                                  :class="{ 'is-invalid': errors.length }">
                                <b-form-invalid-feedback v-if="errors.length">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </div>
                            <div v-if="!landing.sections.inputs.hasOwnProperty('ShowPhone') || landing.sections.inputs.ShowPhone" class="input-group" :style="inputGroupStyle">
                              <PhoneInput
                                v-model="contact.phone"
                                name="Phone"
                                :placeholder="landing.sections.inputs.PhonePlaceholder"
                                :rules="!landing.sections.inputs.hasOwnProperty('PhoneRequired') || landing.sections.inputs.PhoneRequired ? 'required|phone' : 'phone'"
                              />
                            </div>
                          </div>
                          <div
                            class="widget-block widget-content"
                          >
                            <div style="text-align:center;">
                              <button :style="actionButtonStyle" :disabled="loadingSendContact">
                                <template v-if="!loadingSendContact">
                                  {{ landing.sections.actionButton.Label }}
                                </template>
                                <b-spinner v-else></b-spinner>
                              </button>
                              <div class="disclaimer-section">
                                <b-form-checkbox v-if="landing.sections.actionButton.ShowDisclaimerCheckBox" required name="disclaimer">
                                  <span :style="actionDisclaimerStyle">{{ landing.sections.actionButton.Disclaimer | legal(businessName) }}</span>
                                </b-form-checkbox>
                                <span v-else :style="actionDisclaimerStyle">{{ landing.sections.actionButton.Disclaimer | legal(businessName) }}</span>
                              </div>
                            </div>
                          </div>
                        </form>
                      </validation-observer>
                    </template>
                    <div v-else class="text-center">
                      <b-spinner variant="light"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="landing.poweredBy.enabled" class="text-center mt-2">
                <span class="powered-by-content" :style="{ color: landing.sections.legalFooter.Color }">powered by <a :href="landing.poweredBy.url" target="_blank">{{ landing.poweredBy.name }} </a></span>
              </div>
              <div class="legal-container" :style="legalContainerStyle">
                <div>
                  {{ landing.sections.legalFooter.Content }}
                </div>
                <template v-if="landing.sections.legalFooter.PolicyLabel || landing.sections.legalFooter.ServiceLabel">
                  <a v-if="landing.sections.legalFooter.isPolicyLink" class="privacy-link" :href="getFullLink(landing.sections.legalFooter.Link)" 
                    target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.PolicyLabel }}
                  </a>
                  <a v-else class="privacy-link"  :style="{ color: landing.sections.legalFooter.LinkColor }" @click="openLegal('policy')">
                    {{ landing.sections.legalFooter.PolicyLabel }}
                  </a>
                  -
                  <a v-if="landing.sections.legalFooter.isServiceLink" class="privacy-link" :href="getFullLink(landing.sections.legalFooter.ServiceLink)" 
                    target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.ServiceLabel }}
                  </a>
                  <a v-else class="privacy-link"  :style="{ color: landing.sections.legalFooter.LinkColor }" @click="openLegal('service')">
                    {{ landing.sections.legalFooter.ServiceLabel }}
                  </a>
                </template>
                <template v-else>
                  <a class="privacy-link" :href="getFullLink(landing.sections.legalFooter.Link)" target="_blank" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.LinkLabel }}
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!landing" class="text-center" style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
        <h1 class="text-danger">404</h1>
        <h4>Page not found</h4>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <b-spinner></b-spinner>
    </div>
    <b-modal v-if="landing" v-model="showLegalModal" :title="landing.sections.legalFooter[`${selectedPage && selectedPage.name === 'Privacy policy' ? 'Policy' : 'Service' }Label`]" size="lg">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="selectedPage" v-html="selectedPage.content">
      </div>
      <!--eslint-enable-->
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showLegalModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import postscribe from 'postscribe'

export default {
  data() {
    return {
      loadingPage: false,
      landing: null,
      loadingSendContact: false,
      landing_id: this.$route.params.id,
      token_id: this.$route.params.token,
      contact: {
        name: null,
        email: null,
        bday_month: "",
        bday_day: "",
        anniv_month: "",
        anniv_day: "",
        phone: null,
        business_name: '',
        website_url: '',
        business_id: this.$route.params.business,
        referrer: localStorage.getItem('referrer'),
        page_url: window.location.href.split('?')[0],
      },
      contactCustomFields: {},
      activeStep: 'inputs',
      pages: [],
      showLegalModal: false,
      selectedPage: null,
      href: location.href,
      token: null,
      timer: null,
      customFields: [],
      voteCheckedOptions: {},
      voteError: null,
    }
  },

  computed: {
    businessName() {
      return this.landing ? this.landing.business_name : 'N/A'
    },
    
    voteOptions() {
      let options = []
      if (this.landing.type === 'vote') {
        options = this.landing.voteOptions
      }
      return options.filter(item => item.text)
    },

    voteCheckedIds() {
      let res = []
      Object.keys(this.voteCheckedOptions).forEach(key => {
        if (this.voteCheckedOptions[key]) res.push(key)
      })
      return res
    },

    sectionCustomFields() {
      if (!this.landing.sections.inputs.CustomFields) {
        // eslint-disable-next-line
        this.landing.sections.inputs.CustomFields = []
      }
      const customFieldIds = this.customFields.flatMap(item => item.id)
      return this.landing.sections.inputs.CustomFields.filter(item => customFieldIds.includes(item.Id))
        .sort((a, b) => a.id < b.id ? 1 : -1 )
    },

    deviceContainerInner() {
      return {
        backgroundColor: this.landing.sections.styling.BackgroundColor,
      }
    },

    optionStyle() {
      return {
        width: '100%',
        padding: '12px 18px 12px 46px',
        fontSize: `${this.landing.votes.options.FontSize}px`,
        borderRadius: `${this.landing.votes.options.Roudness}px`,
        border: `1px solid ${this.landing.votes.options.BorderColor}`,
        backgroundColor: this.landing.votes.options.BackgroundColor,
        color: this.landing.votes.options.Color,
      }
    },

    containerSectionStyle() {
      return {
        borderRadius: `${this.landing.sections.styling.Roudness}px`,
      }
    },

    logoContainerStyle() {
      return {
        textAlign: this.landing.sections.logo.Align,
        backgroundColor: this.landing.sections.logo.BackgroundColor,
        padding: `${this.landing.sections.logo.Padding}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    },

    legalContainerStyle() {
      return {
        margin: `10px 20px`,
        textAlign: this.landing.sections.legalFooter.Align,
        color: this.landing.sections.legalFooter.Color,
        fontSize: `${this.landing.sections.legalFooter.fontSize}px`,
      }
    },

    contentContainerStyle() {
      let style = {
        paddingBottom: '10px',
        overflow: 'hidden',
      }

      if (!this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        style.borderRadius = `${this.landing.sections.styling.Roudness}px`
        if (this.activeStep === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      }

      return style
    },

    landingWidgetStyle() {
      if (this.landing.sections.styling.Fullscreen) {
        return {

        }
      }

      return  {
        padding: '30px 10px',
      }
    },

    landingContainerStyle() {
      let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.landing.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
      }

      if (this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        if (!this.landing.sections.logo.Show)
          style.paddingTop = '30px'
        if (this.activeStep === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      } else {
        style.width = `${this.landing.sections.styling.MaxWidth}px`
      }

      return style
    },

    inputGroupStyle() {
      return {
        width: '100%',
        marginBottom: '15px',
      }
    },

    inputStyle() {
      return {
        width: '100%',
        padding: '12px 18px',
        fontSize: `${this.landing.sections.inputs.FontSize}px`,
        borderRadius: `${this.landing.sections.inputs.Roudness}px`,
        border: `1px solid ${this.landing.sections.inputs.BorderColor}`,
        backgroundColor: this.landing.sections.inputs.BackgroundColor,
        color: this.landing.sections.inputs.Color,
      }
    },

    mainContainerStyle() {
      if (!this.landing.sections.styling.Fullscreen) {
        return {
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: `${this.landing.sections.main.Padding}px`,
        }
      } else {
        return {
          width: `${this.landing.sections.styling.MaxWidth}px`,
          padding: `${this.landing.sections.main.Padding}px`,
          paddingTop: '50px',
          paddingBottom: '0px',
          margin: '0 auto' 
        }
      }
    },

    actionButtonStyle() {
      return {
        backgroundColor: this.landing.sections.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.sections.actionButton.BorderColor || 'transparent',
        color: this.landing.sections.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.sections.actionButton.Roudness}px`,
        fontSize: `${this.landing.sections.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.sections.actionButton.Padding}px`,
        width: '100%',
      }
    },
    
    voteActionButtonStyle() {
      return {
        backgroundColor: this.landing.votes.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.votes.actionButton.BorderColor || 'transparent',
        color: this.landing.votes.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.votes.actionButton.Roudness}px`,
        fontSize: `${this.landing.votes.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.votes.actionButton.Padding}px`,
        width: '100%',
      }
    },

    actionDisclaimerStyle() {
      return {
        color: this.landing.sections.actionButton.DisclaimerColor,
        fontSize: `${this.landing.sections.actionButton.DisclaimerFontSize}px`,
        display: 'block',
        width: '100%',
      }
    },

    videoType() {
      let link = this.landing.sections.styling.BackgroundVideo || ""
      if (link.match(/\/\/www\.youtube\.com/)) {
        return 'youtube'
      } else if(link.match(/\/\/vimeo.com/)) {
        return 'vimeo'
      }

      return 'video'
    },

    videoSource() {
      let link = this.landing.sections.styling.BackgroundVideo || ""

      if (this.videoType === 'youtube') {
        let videoId = link.replace(/.*\?v=/, '').replace(/&.*/, '')
        return `https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1`
      } else if(this.videoType === 'vimeo'){
        let videoId = link.replace(/.*com\//, '')
        return `https://player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;muted=1&amp;background=1`
      }

      return link.replace(/(https|http)/, 'https')
    },
  },

  mounted() {
    if (this.$route.params.token) {
      this.getPageByToken()
    } else {
      this.getPage()
    }
    
    document.body.style.paddingBottom = 0
  },

  methods: {
    onVoteSubmit() {
      this.voteError = null;
      if (!this.voteCheckedIds.length) {
        this.voteError = 'Please select at least one option.'
        return;
      }

      this.activeStep = ''
      setTimeout(() => {
        this.activeStep = 'inputs'
      }, 50);
    },
    
    onVoteOptionChange() {
      if (!this.landing.votes.options.AllowMultiple) {
        this.voteCheckedOptions = {}
      }
    },
    
    getPageByToken() {
      this.loadingPage = true

      this.$store.dispatch('landing/findByToken', this.$route.params.token)
        .then(res => {
          this.loadingPage = false
          localStorage.setItem('referrer', this.$route.params.token)
          this.landing = res.data 
          if (this.landing.type === 'vote') {
            this.activeStep = 'vote'
          }
          this.customFields = res.customFields 
          this.pages = res.pages
          this.addCustomStyle()
          this.addScript()
          this.contact.business_id = this.landing.business_id
        })
        .catch((e) => {
          this.loadingPage = false
        })
    },

    getFullLink(link)
    {
      if (link && !link.startsWith('http'))
      {
        return 'https://' + link
      }
      return link
    },

    getPage() {
      this.loadingPage = true

      this.$store.dispatch('landing/findByTitle', { id: this.$route.params.id, business_id: this.$route.params.business })
        .then(res => {
          this.loadingPage = false
          this.landing = res.data 
          if (this.landing.type === 'vote') {
            this.activeStep = 'vote'
          }
          this.customFields = res.customFields
          this.pages = res.pages
          this.addCustomStyle()
          this.addScript()
        })
        .catch((err) => {
          console.log(err)
          this.loadingPage = false
        })
    },

    sendContact() {
      this.loadingSendContact = true

      const params = {
        type: this.token_id ? 'loop' : 'landing',
        id: this.token_id ? this.token_id: this.landing_id,
        contact: {
          ...this.contact,
          customFields: this.contactCustomFields,
          votes: this.voteCheckedIds,
        },
      }
      this.$store.dispatch('landing/sendContact', params)
        .then((res) => {
          if (this.landing.sections.thanks.IsRedirect && this.landing.sections.thanks.RedirectLink) {
            let redirect = this.landing.sections.thanks.RedirectLink

            if (!this.landing.sections.thanks.RedirectLink.startsWith('http')) {
              redirect = 'https://' + this.landing.sections.thanks.RedirectLink
            }
            location.href = redirect
          } else {
            this.loadingSendContact = false
            if (this.landing.type === 'loop') {
              this.token = res.reward.token
            }
            if (this.landing.type === 'vote') {
              this.$router.push({ name: 'result', params: { id: this.$route.params.id, business_id: this.$route.params.business } })
            } else if (this.token) {
              this.$router.push({ name: 'thanks', params: { token: this.token } })
            } else {
              this.activeStep = 'thanks'
            }
            
            this.contact = {
              ...this.contact,
              name: null,
              email: null,
              bday_month: "",
              bday_day: "",
              anniv_month: "",
              anniv_day: "",
              phone: null,
              business_name: '',
              website_url: '',
            }
          }
        })
        .catch(() => {
          this.loadingSendContact = false
        })
    },

    selectStep(step) {
      this.activeStep = step
    },

    addCustomStyle() {
      document.getElementById('landing-css') && document.getElementById('landing-css').remove()

      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'landing-css'
      style.innerHTML = `
        .landing-container .vti__dropdown {
          background-color: ${this.landing.sections.inputs.BackgroundColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
        }
        .landing-container .vti__input {
          padding: 12px 20px 12px 5px;
          height: initial;
          font-size: ${this.landing.sections.inputs.FontSize}px;
          color: ${this.landing.sections.inputs.Color};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }
        .landing-container .vue-tel-input {
          border-color: ${this.landing.sections.inputs.BorderColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }`
      document.querySelector('head').append(style)
    },

    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },

    addScript() {
      // Init Script
      if ($('#custom-scripts').length) {
        $('#custom-scripts').next().remove()
        $('#custom-scripts').remove()
      }

      let scriptTypes = ['Custom']

      let totalScripts = []
      let scriptSource = this.landing.sections.scripts
      
      for(let type of scriptTypes) {
        if (scriptSource && scriptSource[type]) {
          totalScripts.push(scriptSource[type])
        }
      }

      // Check Total Scripts
      if (totalScripts.length && !$('#custom-scripts').length) {
        $('body').append(`<div id="custom-scripts"></div>`)

        let scriptContent = totalScripts.join('')

        postscribe('#custom-scripts', scriptContent, {
          autoFix: true,
          done: () => {
            this.clearTimer()

            this.timer = setTimeout(() => {
              dispatchEvent(new Event('load'))
            }, 5000)
          }
        })
      }
    },

    openLegal(page) {
      this.showLegalModal = true
      this.selectedPage = this.pages[page]
    },
  },
}
</script>

<style lang="scss">
.disclaimer-section {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
    cursor: pointer;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
  span {
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.options-section {
  .custom-checkbox {
    padding: 0 !important;
    &.allow-multiple {
      .custom-control-label:before,
      .custom-control-label:after {
        border-radius: 3px;
      }
    }
  }
  .custom-control-label {
    width: 100%;
    display: block;
    padding: 12px 12px 12px 48px;
    cursor: pointer;
    min-height: 52.5px;
    &.empty {
      color: #aaa;
    }
    &:hover {
      background-color: #4284f430;
    }
  }

  .custom-control-input[type="checkbox"]:checked {
    & + .custom-control-label {
      background-color: #4284f4;
      color: #fff;
      &:before {
        border-color: #fff;
      }
    }
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 1.6rem;
    height: 1.6rem;
    top: 0.875625rem;
    left: 0.75rem;
    border-width: 2px;
    border-radius: 50%;
  }
}
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}
.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.input-group span {
  width: 100%;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.legal-container {
  .privacy-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline!important;
    }
  } ;
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
    .landing-container {
      width: 100%!important;
      .main-container {
        width: 100%!important;
      }
    }
  }
}
</style>
